body {
  background-color: #282c34;
}
.error {
  padding: .5rem;
  color: #ff6f00;
  background: #616161;
  font-weight: bold;
}

.blended {
  opacity: .3;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 1900px;
  padding: 1rem;
  margin: auto;
}

.App-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

.App-logo {
  height: 60px;
}

.App-link {
  color: #61dafb;
}

.device-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.simulator-list-item {
  width: 120px;
  height: 100px;
  margin-right: .4rem;
  margin-bottom: .4rem;
  padding: .3rem;
  background-color: #666;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  border: .1rem solid #e3e3e3;
  position: relative;
  font-size: 0.9rem;
}

.simulator-list-item-progress-container {
  width: 100%;
}

.active {
  background-color:#58c372;
}

.warning {
  background-color: #efef71;
  color: #494908;
}

.requesting-call {
  animation: callBg 0.5s infinite;
  animation-direction: alternate;
}
@keyframes callBg {
  from {background-color: #383434;}
  to {background-color: #ff6f00;}
}

.alert {
  background-color:#fb5959;
}

.simulator-list-item.selected {
  border-width: 4px !important;
  border-color: #ffffff;
}

.break {
  flex: 1 0 100%;
}

.full-width {
  width: 100%;
}

.top-margin {
  margin-top: 1rem;
}
.top-margin-large {
  margin-top: 3rem;
}

.profile {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/*TODO rename to device-list-item etc*/
.simulator-list-item.door {
  border-radius: 1.5rem;
  border: .1rem dashed #ffffff;
}

.device-problem-icon {
  font-size: x-large !important;
}

.new-sim-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: smaller !important;
}

@media only screen and (max-width: 1200px) {
  .simulator-list-item.minified {
    width: 60px;
    height: 50px;
    margin: 0.1rem;
    padding: .1rem;
    font-size: .4rem !important;
    color: rgb(227, 227, 227);
    border-width: .05rem;
  }

  .minified .device-problem-icon{
    font-size: small;
  }
  .simulator-list-item.door.minified {
    border-radius: .7rem;
  }
}

.simulator-alert {
  color: #000;
  font-weight: bold;
}

.gray-container {
  background-color: #e6e6e6;
  border-radius: 0.5rem;
  color: black;
  padding: 1rem;
}

.device-container {
  text-align: left;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .device-container {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 500px) {
  .device-tools-container {
    padding-right: 1rem;
    min-width: 500px;
  }
}


.device-status-container {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.smaller {
  font-size: smaller;
}

.larger {
  font-size: larger;
}

.margin-top {
  margin-top: 1rem;
}

.margin-right {
  margin-right: .8rem;
}

.margin-left {
  margin-left: .8rem;
}

.calendar-overview {
  width: 100%;
  max-width: 500px;
  max-height: 400px;
  padding: .8rem;
}

@media only screen and (max-width: 1200px) {
  .calendar-overview {
    max-width: 100%;
  }
}

.calendar-events {
  background: #dddddd;
  padding: .5rem;
  border-radius: .5rem;
}

.calendar-event {
}
.calendar-event-details > div{
  margin-top: .5rem;
}

.device-section-container {
  background: #dddddd;
  padding: .5rem;
  margin-top: 1rem;
  border-radius: .5rem;
}

.current-notes {
  background: #1c1e28;
  border-radius: .5rem;
  font-size: small;
  padding: .8rem;
  margin-right: 2rem;
}

@media only screen and (max-width: 1200px) {
  .current-notes {
    margin-right: 0;
  }
}

.mono-font {
  font-family: monospace;
}

.lesson-status-list {
  padding-left: .5rem;
  padding-right: .5rem;
}

.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}

.reload-bar {
  max-height: 10px;
  max-width: 300px;
}

@media only screen and (max-width: 1200px) {
  .top-container {
    flex-direction: column;
  }
}

.mainmenu {
  text-align: left;
  margin-right: 1rem;
}

.v-align-center {
  display: flex;
  align-items: center;
}

.h-align-center {
  display: flex;
  align-items: center;
}

.h-align-sides {
  display: flex;
  justify-content: space-between;
}

.locations-popover {
  padding: 1rem;
}

.align-left {
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.note {
  padding-top: .07rem;
  padding-bottom: .07rem;
}

.note:hover {
  background-color: #ed6c05;
}

.logo-container {
  text-align: left;
}

.logo-container .MuiSwitch-track {
  background-color: #ccc;
}

.small-text {
  font-size: smaller;
}